// vendor
import 'bootstrap';
import 'parsleyjs';
import 'slick-carousel/slick/slick';
import toastr from 'toastr';
window.toastr = toastr;

import objectFitImages from 'object-fit-images';
objectFitImages($('[data-selector="ofi-image"]'));

import 'select2/dist/js/select2';
$.fn.select2.defaults.set('theme', 'bootstrap');

// components
import './components/parsley-validators';
import './components/contact-us-form';
import './components/main';
import './components/menu';
import './components/homepage';
import './components/header';
import './components/parents';
import './components/teachers';
import './components/promo-for-schools';
import './components/fetc20';
import './components/kid-profile-create-page';
import './components/quote-request-page';
import './components/replace-pincode';
import './components/apps-page';
import './components/sign-up-teacher';
import './components/account-settings';
import './components/school-plans';
import './components/change-card';
import './components/reviews-section-parent';
import './components/main-banner';
import './components/main-banner-timer';

import './gift/redeem-existing';
import './gift/main';
import './gift/purchase';

import './payment/subscription-promo-payment';
import './payment/subscription-plans-sample';
import './payment/subscription-plans-slider';

import './help-center/help-center-details';
import './help-center/help-center';

import './website/index';

import './components/plans-timer';
import './components/sign-up-page';
import './components/invoice-payment';
import './components/distributor-code-activate';
import './components/go-to-top-btn';
import './components/survey';
import './components/share-social';
import './components/resource-center';
import './components/subject-section';
import './components/sign-up-parent-complete';
import './components/e-blox';
import './components/chess-landing';
import './components/shipping-addon-form';
import './components/parent-reports';
import './components/sign-up-chess';
import './components/upgrade-subscription';
import './components/related-worksheets';
import './components/my-messages';
import './components/website-plans-modal';
import './components/website-cr-tg-plans-modal';
import './components/website-sign-up-modal';
import './components/app-list';
import './components/tgc-landing';
import './components/learning-essentials';
import './components/website-account-type-modal';
import './components/monetization-popup';
import './components/tg-password-reset';
import './components/youtube';
import './components/lesson-video';
import './components/free-sign-up-modal';
import './components/app-detail';
import './components/app-detail-tag-landing';
import './components/app-detail-modal-slider';
import './components/skilled-worksheet-list';

import './website/material-detail';
